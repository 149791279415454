<!-- 课程列表 -->
<template>
  <div class="flex-col space-y-20 page">

    <div class="flex-col space-y-30 section_3">
      <div class="flex-col group_3">
        <div class="flex-row justify-between group_4">
          <div class="flex-row text-wrapper"><span class="text_3">课程报名中心-></span><span class="text_2">选择报名人</span></div>
		  <div class="flex-row">
			  <img
			    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16622994929819924446.png"
			    class="image_7" @click="gobackBtn" />
			  <span class="text_34" @click="gobackBtn">返回</span>
		  </div>
		</div>
        <div class="flex-col space-y-15 group_5">
          <div class="flex-row group_6 justify-between">
			  <div class="g6_left flex-row">
				<span class="text_11">姓名</span>
				<el-input placeholder="请输入姓名查询" v-model="keyword" clearable class="text_12" style="width: 15rem;"></el-input>
				<div class="flex-col items-center text-wrapper_2" @click="searchBtn"><span class="text_13">查询</span></div>
				<div class="flex-col items-center text-wrapper_3" @click="resetBtn"><span class="text_14">重置</span></div>
			  </div>
            <div class="g6_right flex-row">
				<span class="text_11">已选成员：</span><span class="text_11">{{baomingNum}}</span>
				<div class="flex-col items-center text-wrapper_2" @click="baomingBtn"><span class="text_13">报名</span></div>
            </div>
			
          </div>
          <el-table :row-key="getRowId" 
		  ref="multipleTable"
		  :data="list" 
		  style="width: auto" 
		  :header-cell-style="{background:'#eef0f7bf'}" 
		  v-loading="loading" 
		  element-loading-text = "数据正在加载中" 
		  element-loading-spinner = "el-icon-loading" 
		  @selection-change="handleSelectionChange">
              <el-table-column :reserve-selection="true" type="selection" width="55" align="center"></el-table-column>
			  <el-table-column type="index" :index="getIndex" label="序号" width="auto" align="center"></el-table-column>
              <el-table-column prop="student_number" label="学号" width="400px" align="center"></el-table-column>
              <el-table-column prop="name" label="姓名" width="auto" align="center"></el-table-column>
              <el-table-column prop="sex_txt" label="性别" width="auto" align="center"></el-table-column>
              <el-table-column prop="id_card" label="身份证号码" width="auto" align="center"></el-table-column>
              <el-table-column prop="mobile" label="联系方式" width="auto" align="center"></el-table-column>
              <el-table-column prop="education_cate_name" label="学历" width="auto" align="center"></el-table-column>
            </el-table>
        </div>
      </div>
      <div class="flex-row justify-between group_30">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :total="count">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
       loading:false,
       page:1,
       limit:10,
       totalpage:0,
       count:0,
       baomingArr:[],
	   baomingUidArr:[],
       baomingNum:0,
       keyword: '',
       list: [],
       identity:sessionStorage.getItem('identity')
	  };
    },
	beforeCreate(){

	},
	created(){
		let that = this;
		that.getTrainObject();
		that.getCurriculumCate();
		that.getTrainType();
		that.getUserLists();
	},
    methods: {
		//获取培训对象
		getTrainObject(){
			let that = this;

			that.$request.getTrainObject().then((res)=>{
				if(res.code == 200){
					that.trainObjectArr = res.datas;
				}
			});
		},
		//获取课程类型
		getCurriculumCate(){
			let that = this;
			that.$request.getCurriculumCate().then((res)=>{
				if(res.code == 200){
					that.curriculumCateArr = res.datas;
				}
			});
		},
		//获取翻页递增索引
		getIndex(index){
			let that = this;
			let page = that.page;
			let limit = that.limit;
			return (page - 2) * limit + index + 1;
		},

		//获取培训类型
		getTrainType(){
			let that = this;
			that.$request.getTrainType().then((res)=>{
				if(res.code == 200){
					that.trainTypeArr = res.datas;
				}
			});
		},
    //搜索
    searchBtn(){
      let that = this;
      that.page = 1;
      that.totalpage = 0;
      that.getUserLists();
    },
    //重置搜索
    resetBtn(){
      let that = this;
      that.page = 1;
      that.totalpage = 0;
      that.trainObject = undefined;
      that.curriculumCate = undefined;
      that.trainType = undefined;
      that.keyword = '';
      that.getUserLists();
    },
    handleCurrentChange(val){
      let that = this;
      that.page = val;
      that.getUserLists();
    },
	//选择后去报名
	baomingBtn(){
		let that = this;
		if(that.baomingUidArr.length <= 0){
			that.$myMessage.warning('请选择学员报名！');
			return false;
		}
		let baomingUidStr = that.baomingUidArr.toString();
		let curriculum_id = this.$route.query.curriculum_id;
		if(!curriculum_id){
			that.$myMessage.warning('请选择要报名的课程！');
			return false;
		}
		that.loading=true;
		let param = {
			uids:baomingUidStr,
			curriculum_id:curriculum_id,
		};
		that.$request.enterpriseBaoming(param).then((res)=>{
			if(res.code == 200){
				that.loading=false;
				//报名成功，跳转到支付页面
				that.$router.push({
				  'path': '/company/order/detail',
				  query: {
				    ordersn: res.datas.ordersn
				  }
				})
			}
		});
	},
		//获取课程列表
		getUserLists(){
			let that = this;
			//that.$store.commit('showLoading')
			let curriculum_id = this.$route.query.curriculum_id;
			that.loading=true;
			let param = {
				page:that.page,
				limit:that.limit,
				keyword:that.keyword,
				curriculum_id:curriculum_id,
			};
			that.$request.getUserLists(param).then((res)=>{
				if(res.code == 200){
					that.list = res.datas;
					that.page=that.page + 1;
					that.totalpage=res.total_page;
					that.count = res.count;
					//that.$store.commit('hideLoading')
					that.loading=false;
					if (this.baomingArr .length > 0) { // 判断是否存在勾选过的数据
					 res.datas.forEach( row => {  // 获取数据列表接口请求到的数据
					     this.baomingArr .forEach( item => {  // 勾选到的数据
					      if(row.uid === item){
					          this.$refs.multipleTable.toggleRowSelection(item, true); // 若有重合，则回显该条数据
					        }
					      });
					   })
					}
				}
			});
		},
		//返回
		gobackBtn() {
		  let that = this;
		  that.$router.go(-1)
		},
		getRowId(row) {
		  return row.uid;  // row-key绑定的必须为唯一值
		},
		//当选择项发生变化时会触发该事件
		handleSelectionChange(val) {
			//console.log(val);
		  let that = this;
		  that.baomingUidArr = []
		  for(var i in val) {
			  that.baomingUidArr.push(val[i].uid);
		  }
		  that.baomingUidArr = that.baomingUidArr;
		  that.baomingNum = that.baomingUidArr.length;
		  that.baomingArr = val;
		},
	},
  };
</script>

<style scoped lang="css">
  .space-y-20 > *:not(:first-child) {
    margin-top: 1.25rem;
  }
  .page {
    padding-bottom: 15.56rem;
    background-color: #f5f6fa;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
	padding: 1.25rem 1.25rem 1.88rem;
	flex: 1 1 auto;
  }
  .section_1 {
    padding: 0.63rem 1.25rem 0.63rem 3.13rem;
    background-color: #ffffff;
  }
  .space-x-12 > *:not(:first-child) {
    margin-left: 0.75rem;
  }
  .group {
    margin: 0.13rem 0;
    overflow: hidden;
    width: 15.56rem;
    height: 2.88rem;
  }
  .image {
    flex-shrink: 0;
    width: 2.88rem;
    height: 2.88rem;
  }
  .image_7 {
    flex-shrink: 0;
    width: 1.56rem;
    height: 1.56rem;
    cursor: pointer;
  }
  .text_34 {
    margin: 0.41rem 0 0.38rem 0.28rem;
    color: #0074ff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.78rem;
    cursor: pointer;
  }
  .text {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 1.5rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.41rem;
  }
  .space-x-15 > *:not(:first-child) {
    margin-left: 0.94rem;
  }
  .group_1 {
    overflow: hidden;
    width: 12.88rem;
    height: 3.13rem;
  }
  .space-x-5 > *:not(:first-child) {
    margin-left: 0.31rem;
  }
  .group_2 {
    overflow: hidden;
    width: 5.63rem;
    height: 3.13rem;
  }
  .image_1 {
    flex-shrink: 0;
    width: 2.5rem;
    height: 3.13rem;
  }
  .text_1 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 0.88rem;
  }
  .section_2 {
    align-self: center;
    background-color: #d8d8d8;
    width: 0.094rem;
    height: 0.81rem;
  }
  .image_2 {
    align-self: center;
    width: 1.13rem;
    height: 1.13rem;
  }
  .space-y-30 > *:not(:first-child) {
    margin-top: 1.88rem;
  }
  .section_3 {
    margin-right: 1.25rem;
    padding: 0 0.94rem 2.19rem;
    /* align-self: flex-end; */

    background-color: #ffffff;
    border-radius: 0.63rem;
    /* width: 71.56rem; */
  }
  .group_3 {
    padding-top: 0.94rem;
  }
  .group_4 {
    margin-right: 0.31rem;
    overflow: hidden;
    border-bottom: solid 0.016rem #c0c4cc;
  }
  .text-wrapper {
    padding: 0.22rem 0 1.28rem;
    border-bottom: solid 0.16rem #0074ff;
  }
  .text_2 {
    color: #0074ff;
    font-size: 1.13rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 1.06rem;
  }
  .space-y-15 > *:not(:first-child) {
    margin-top: 0.94rem;
  }
  .group_5 {
    padding: 1.69rem 0 0.94rem;
  }
  .group_6 {
    align-self: flex-start;
    overflow: hidden;
    width: 100%;
  }
  .space-x-8 > *:not(:first-child) {
    margin-left: 0.5rem;
  }
  .group_7 {
    overflow: hidden;
	margin-right: 1rem;
    /* width: 12.63rem;
    height: 2.19rem; */
  }
  .text_3 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .space-x-45 > *:not(:first-child) {
    margin-left: 2.81rem;
  }
  .section_4 {
    flex-shrink: 0;
    padding: 0.59rem 0.5rem 0.66rem;
    border-radius: 0.31rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
  }
  .text_4 {
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .image_6 {
    margin-right: 0.16rem;
    flex-shrink: 0;
    width: 0.88rem;
    height: 0.88rem;
  }
  .group_8 {
    margin-left: 0.94rem;
    overflow: hidden;
    width: 10.69rem;
    height: 2.19rem;
  }
  .text_5 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .space-x-14 > *:not(:first-child) {
    margin-left: 0.88rem;
  }
  .text_7 {
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text_11 {
    margin-left: 1rem;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text-wrapper_1 {
    margin-left: 0.53rem;
    padding: 0.59rem 0 0.72rem;
    border-radius: 0.31rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
  }
  .text_12 {
    margin-left: 0.5rem;
    margin-right: 0.56rem;
    color: #c0c4cc;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text-wrapper_2 {
    margin-left: 0.94rem;
    padding: 0.69rem 0;
    background-color: #0074ff;
    border-radius: 0.31rem;
    width: 3rem;
    height: 2.19rem;
    cursor: pointer;
  }
  .text_13 {
    color: #ffffff;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text-wrapper_3 {
    margin-left: 0.91rem;
    padding: 0.63rem 0 0.72rem;
    border-radius: 0.31rem;
    width: 3rem;
    height: 2.19rem;
    border: solid 0.031rem #dcdfe6;
    cursor: pointer;
  }
  .text_14 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .section_5 {
    margin-right: 0.31rem;
    height: 27.5rem;
    border: solid 0.031rem #ebeef5;
    position: relative;
  }
  .text_15 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.47rem;
    position: absolute;
    left: 1.53rem;
    top: 3.44rem;
  }
  .text_16 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    top: 3.28rem;
  }
  .group_9 {
    width: 69.38rem;
    height: 27.5rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
  }
  .section_6 {
    background-color: #e9edf380;
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    left: 16.78rem;
    top: 0;
  }
  .section_7 {
    background-color: #ebeef5;
    width: 0.031rem;
    height: 27.5rem;
    position: absolute;
    right: 4.25rem;
    top: 0;
  }
  .image_10 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    left: 23.16rem;
    bottom: 0;
  }
  .image_11 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    left: 30.22rem;
    bottom: 0;
  }
  .image_12 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    right: 32.03rem;
    bottom: 0;
  }
  .image_13 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    right: 26.97rem;
    bottom: 0;
  }
  .image_14 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    right: 20.34rem;
    bottom: 0;
  }
  .image_15 {
    width: 0.063rem;
    height: 27.5rem;
    position: absolute;
    right: 12.28rem;
    bottom: 0;
  }
  .section_8 {
    padding: 0.81rem 0.97rem 0.88rem;
    background-image: linear-gradient(180deg, #eef0f7bf 0%, #eef0f7 100%);
    width: 69.38rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .space-x-29 > *:not(:first-child) {
    margin-left: 1.81rem;
  }
  .text_17 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text_18 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .space-x-53 > *:not(:first-child) {
    margin-left: 3.31rem;
  }
  .group_11 {
    margin-right: 0.25rem;
  }
  .text_19 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text_20 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text_21 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text_22 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text_23 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text_24 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text_25 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .text_26 {
    color: #3d3d3d;
    font-size: 0.88rem;
    font-family: Source Sans Pro;
    line-height: 0.81rem;
  }
  .space-x-123 > *:not(:first-child) {
    margin-left: 7.69rem;
  }
  .group_12 {
    padding: 0.25rem 1.34rem 0.91rem 1.59rem;
    overflow: hidden;
    width: 69.38rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 15.63rem;
    border-bottom: solid 0.016rem #ebeef5;
  }
  .space-x-37 > *:not(:first-child) {
    margin-left: 2.31rem;
  }
  .text_27 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }
  .text_28 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .space-x-83 > *:not(:first-child) {
    margin-left: 5.19rem;
  }
  .group_14 {
    flex: 1 1 auto;
    width: 50rem;
  }
  .space-x-76 > *:not(:first-child) {
    margin-left: 4.75rem;
  }
  .text_29 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .text_31 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .group_16 {
    flex: 1 1 auto;
    width: 35.5rem;
  }
  .text_33 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .text_35 {
    margin: 0.41rem 0 0.22rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
  }
  .text_37 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
  }
  .text_39 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }
  .space-x-55 > *:not(:first-child) {
    margin-left: 3.44rem;
  }
  .text_41 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }
  .text_43 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .divider {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 15rem;
  }
  .divider_1 {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 12.5rem;
  }
  .group_18 {
    padding: 0.25rem 1.34rem 0.91rem 1.59rem;
    overflow: hidden;
    width: 69.38rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 8.13rem;
    border-bottom: solid 0.016rem #ebeef5;
  }
  .text_45 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }
  .text_46 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .group_22 {
    padding: 0.25rem 0 0.31rem 0.13rem;
    overflow: hidden;
    width: 66.59rem;
    position: absolute;
    left: 1.41rem;
    right: 1.38rem;
    top: 25.63rem;
  }
  .space-x-33 > *:not(:first-child) {
    margin-left: 2.06rem;
  }
  .text_55 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }
  .text_56 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .group_24 {
    margin-left: 7.69rem;
  }
  .text_57 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .text_58 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .view_9 {
    margin-left: 5.19rem;
  }
  .text_59 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .text_60 {
    margin: 0.41rem 0 0.22rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
  }
  .text_61 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
  }
  .text_62 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }
  .text_63 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }
  .text_64 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .group_26 {
    padding: 0.25rem 1.34rem 0.91rem 1.69rem;
    overflow: hidden;
    width: 69.38rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 23.13rem;
    border-bottom: solid 0.016rem #ebeef5;
  }
  .space-x-36 > *:not(:first-child) {
    margin-left: 2.25rem;
  }
  .text_65 {
    margin-top: 0.16rem;
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
  }
  .text_66 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
  }
  .divider_2 {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 22.5rem;
  }
  .divider_3 {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 20rem;
  }
  .divider_4 {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 7.5rem;
  }
  .divider_5 {
    background-color: #ebeef5;
    width: 69.38rem;
    height: 0.031rem;
    position: absolute;
    left: 0;
    right: 0;
    top: 5rem;
  }
  .text_75 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    top: 3.28rem;
  }
  .text_76 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    top: 3.28rem;
  }
  .text_77 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    top: 3.28rem;
  }
  .text_78 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    top: 3.69rem;
  }
  .text_79 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    top: 3.44rem;
  }
  .text_80 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    top: 3.41rem;
  }
  .text_81 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    top: 3.41rem;
  }
  .text_82 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    top: 3.28rem;
  }
  .text_83 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    left: 1.5rem;
    top: 5.91rem;
  }
  .text_84 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    top: 5.78rem;
  }
  .text_85 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    top: 5.78rem;
  }
  .text_86 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    top: 5.78rem;
  }
  .text_87 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    top: 5.78rem;
  }
  .text_88 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    top: 6.19rem;
  }
  .text_89 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    top: 5.94rem;
  }
  .text_90 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    top: 5.91rem;
  }
  .text_91 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    top: 5.91rem;
  }
  .text_92 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    top: 5.78rem;
  }
  .text_93 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.47rem;
    position: absolute;
    left: 1.47rem;
    top: 10.94rem;
  }
  .text_94 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    top: 10.78rem;
  }
  .text_95 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    top: 10.78rem;
  }
  .text_96 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    top: 10.78rem;
  }
  .text_97 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    top: 10.78rem;
  }
  .text_98 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    top: 11.19rem;
  }
  .text_99 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    top: 10.94rem;
  }
  .text_100 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    top: 10.91rem;
  }
  .text_101 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    top: 10.91rem;
  }
  .text_102 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    top: 10.78rem;
  }
  .text_103 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_104 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_105 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_106 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_107 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_108 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_109 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_110 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_111 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_112 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .text_113 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.47rem;
    position: absolute;
    left: 1.5rem;
    bottom: 8.53rem;
  }
  .text_114 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    bottom: 8.47rem;
  }
  .text_115 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    bottom: 8.47rem;
  }
  .text_116 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    bottom: 8.47rem;
  }
  .text_117 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    bottom: 8.47rem;
  }
  .text_118 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    bottom: 8.69rem;
  }
  .text_119 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    bottom: 8.53rem;
  }
  .text_120 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    bottom: 8.53rem;
  }
  .text_121 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    bottom: 8.53rem;
  }
  .text_122 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    bottom: 8.47rem;
  }
  .text_123 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    left: 1.5rem;
    bottom: 6.03rem;
  }
  .text_124 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 4.19rem;
    bottom: 5.97rem;
  }
  .text_125 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 17.94rem;
    bottom: 5.97rem;
  }
  .text_126 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 25.72rem;
    bottom: 5.97rem;
  }
  .text_127 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    left: 32.44rem;
    bottom: 5.97rem;
  }
  .text_128 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.063rem;
    position: absolute;
    right: 29.69rem;
    bottom: 6.19rem;
  }
  .text_129 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    font-weight: 900;
    line-height: 0.5rem;
    position: absolute;
    right: 22.28rem;
    bottom: 6.03rem;
  }
  .text_130 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 14.16rem;
    bottom: 6.03rem;
  }
  .text_131 {
    color: #3d3d3d;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.5rem;
    position: absolute;
    right: 6.31rem;
    bottom: 6.03rem;
  }
  .text_132 {
    color: #0074ff;
    font-size: 0.75rem;
    font-family: Source Sans Pro;
    line-height: 0.69rem;
    position: absolute;
    right: 1.38rem;
    bottom: 5.97rem;
  }
  .group_30 {
    margin-left: 0.31rem;
    overflow: hidden;
  }
  .group_31 {
    flex-shrink: 0;
  }
  .text_133 {
    align-self: center;
    color: #3d3d3d;
    font-size: 0.81rem;
    font-family: Source Sans Pro;
    line-height: 0.75rem;
  }
  .image_16 {
    flex-shrink: 0;
    border-radius: 0.13rem;
    width: 1.88rem;
    height: 1.88rem;
  }
  .image_17 {
    margin-left: 1.03rem;
  }
  .text-wrapper_4 {
    margin-left: 0.63rem;
    padding: 0.66rem 0 0.63rem;
    flex-shrink: 0;
    background-color: #0074ff;
    border-radius: 0.13rem;
    width: 1.88rem;
    height: 1.88rem;
  }
  .text_134 {
    color: #ffffff;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    line-height: 0.59rem;
  }
  .text-wrapper_5 {
    margin-left: 0.63rem;
    padding: 0.66rem 0 0.63rem;
    flex-shrink: 0;
    background-color: #f4f4f5;
    border-radius: 0.13rem;
    width: 1.88rem;
    height: 1.88rem;
  }
  .text_135 {
    color: #000000;
    font-size: 0.94rem;
    font-family: Source Sans Pro;
    line-height: 0.63rem;
  }
  .image_18 {
    margin-left: 0.63rem;
  }
  .space-x-4 > *:not(:first-child) {
    margin-left: 0.25rem;
  }
  .group_32 {
    flex-shrink: 0;
    overflow: hidden;
    width: 6.81rem;
    height: 1.88rem;
  }
  .text_136 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.81rem;
    font-family: Source Sans Pro;
    line-height: 0.75rem;
  }
  .text-wrapper_6 {
    padding: 0.63rem 0 0.66rem;
    flex-shrink: 0;
    border-radius: 0.19rem;
    width: 3.75rem;
    height: 1.88rem;
    border: solid 0.031rem #c0c4cc;
  }
  .text_137 {
    color: #3d3d3d;
    font-size: 0.81rem;
    font-family: Source Sans Pro;
    line-height: 0.53rem;
  }
  .text_138 {
    flex-shrink: 0;
    align-self: center;
    color: #3d3d3d;
    font-size: 0.81rem;
    font-family: Source Sans Pro;
    line-height: 0.72rem;
  }
</style>
